<template>
  <div class="justify-center align-center w-full flex">
    <div :class="`flex flex-col ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} max-w-[1156px]`">
      <div :class="`lg:w-[566px] w-full ${reverse ? 'lg:ml-6' : 'lg:mr-6'} lg:mb-0 mb-8 md:px-4`">
        <img
          class="w-full h-auto"
          :src="image_url"
        />
      </div>
      <div :class="`md:pt-14 lg:pt-0 sm:pt-8 lg:max-w-[566px] md:w-full lg:px-6 ${styles.ppage}`">
        <div class="flex flex-row align-center mb-4">
          <img
            class="w-[48px] h-auto mr-4"
            :src="icon_url"
          />
          <h4>{{ title }}</h4>
        </div>
        <div>
          <p class="lg:mb-6 mb-4">{{ text }}</p>

          <a
            target="_blank"
            :href="button.url"
            v-if="button"
          >
            <button class="btn-secondary">{{ button.text }} <img
                :src="btn_icon"
                class="w-[24px] h-auto ml-2"
              /></button>

          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import styles from "./styles";

export default {
  data: () => ({
    styles: styles,
  }),
  props: {
    icon_url: { type: String },
    image_url: { type: String },
    title: { type: String },
    text: { type: String },
    btn_icon: { type: String },
    reverse: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Object,
      default: null,
    },
  },
};
</script>