<template>
  <v-btn class="nav-drawer-icon" icon="menu" size="32" :elevation="0" variant="flat" @click="toggle()">
  </v-btn>
</template>
<script>
import bus from "glib-web/utils/eventBus";

export default {
  props: {
    spec: { type: Object, required: true },
  },
  methods: {
    toggle() {
      bus.$emit("glib-drawers/toggle");
    },
  },
};
</script>
<style>
.nav-drawer-icon i.v-icon {
  color: #47495F;
}
</style>
