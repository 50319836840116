<template>
    <div
        :class="`flex lg:flex-row flex-col justify-center align-center pt-[96px] md:pt-[180px] ${styles.ppage} relative`">
        <div class="w-[1047px] h-[619px] absolute top-[100px] right-0 absolute">
            <div class="feature-glitter w-full h-full z-10 hidden lg:block"></div>
            <div class="w-full h-full relative">
                <div class="feature-glitter-round z-0 hidden lg:block"></div>
            </div>
        </div>

        <div class="lg:w-[448px] w-full lg:mr-6 relative z-20">
            <h2 class="mb-6 z-30 text-center md:text-left">Empower sustainable tourism development </h2>
            <p class="z-30 text-center md:text-left">Design a high-performing sustainability strategy to maximize
                enterprise values.</p>
            <div class="feature-glitter-small z-[-10] hidden lg:block"></div>
        </div>

        <div class="md:w-[678px] w-full pt-[180px] lg:pt-0 lg:mt-0">
            <div class="flex flex-row flex-wrap">
                <template v-for="(feature, index) in features">
                    <div
                        :class="`md:w-[308px] w-full h-[379px] border-0 rounded-[40px] drop-shadow-sm bg-white align-center justify-center px-4 py-8 relative z-30 ${feature.class}`">

                        <div class="flex justify-center">
                            <img class="mb-8 w-128 h-auto rounder-lg" :src="feature.image_url" />
                        </div>
                        <h3 class="mb-4 text-center">{{ feature.title }}</h3>
                        <p class="text-center">{{ feature.subtitle }}</p>


                    </div>
                </template>
            </div>
        </div>
    </div>

</template>

<script>
import styles from './styles';

export default {
    props: {
        obj: {
            type: Object
        }
    },
    data() {
        return {
            styles: styles,
            features: [
                {
                    image_url: this.obj.esg_strat,
                    title: 'Sustainability / ESG Strategy',
                    subtitle: 'Create a revenue-driven sustainable tourism model',
                    class: 'md:mr-[44px] md:mb-8 mb-6'
                },
                {
                    image_url: this.obj.finance_sol,
                    title: 'Inclusive financial solution',
                    subtitle: 'Provide fintech solutions for micro-lending and payment',
                    class: 'md:mt-[-128px] md:mb-8 mb-6'
                },
                {
                    image_url: this.obj.cs_dev,
                    title: 'Custom software development',
                    subtitle: 'Technology to streamline sustainability management',
                    class: 'md:ml-[-16px] md:mr-[53px] md:mb-8 mb-6'
                },
                {
                    image_url: this.obj.market_launch,
                    title: 'Go-to-market launch',
                    subtitle: 'Amplify your ESG with storytelling and growth hack',
                    class: 'md:mt-[-128px] md:mb-8 mb-6'
                }
            ]
        }
    }
}
</script>
<style scoped>
.feature-glitter {
    position: absolute;
    top: 100px;
    right: 0px;

    background: #F4F9FF;
    border-radius: 150px 0px 0px 0px;
}

.feature-glitter-small {
    position: absolute;
    width: 178px;
    height: 178px;
    left: -89px;
    top: 0px;

    background: #FFF5DB;
    border-radius: 0px 0px 100px 0px;
    transform: rotate(-180deg);
}

.feature-glitter-round {
    box-sizing: border-box;

    position: absolute;
    width: 120px;
    height: 120px;
    bottom: -170px;
    right: 120px;

    border: 8.8px solid #FF007A;
    border-radius: 50%;
}
</style>