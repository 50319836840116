<template>
    <div class="w-full py-[80px] px-4 flex justify-center items-center">
        <div class="w-full lg:w-[1156px] mb-10">
            <div class="w-full flex flex-col items-center justify-center">
                <img class="w-[100px] h-[100px] mb-10" :src="spec.imageUrl" />
                <div :class="`font-extrabold text-[75px] ${textColor[spec.mood]} mb-10`">{{ spec.title }}</div>

                <div :class="`w-full bg-[#E6E6E6] rounded-full h-4 lg:w-[360px] sm:w-[300px] mb-10`">
                    <div :class="`${bgColor[spec.mood]} p-0.5 leading-none rounded-full h-4`"
                        :style="{ width: `${spec.score}%` }">
                    </div>
                </div>

                <h2>{{ spec.topic_title }}</h2>
                <p class="mb-10">{{ spec.topicDesc }}</p>

                <div v-if="spec.resourceUrl"
                    class="border-0 bg-[#F3FCFF] py-3 px-4 rounded-2xl flex justify-center items-center mb-10">
                    <span class="mb-0 !text-[14px] inline"><svg class="inline mr-1" width="25" height="24"
                            viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.5 21H14.5V19H21.5V5H3.5V8H1.5V5C1.5 3.89543 2.39543 3 3.5 3H21.5C22.6046 3 23.5 3.89543 23.5 5V19C23.5 20.1046 22.6046 21 21.5 21ZM12.5 21H10.5C10.505 18.6124 9.55472 16.3219 7.861 14.639C6.17821 12.9451 3.88769 11.9948 1.5 12V9.99996C4.41792 9.99224 7.21779 11.1516 9.276 13.22C11.347 15.2784 12.508 18.0801 12.5 21ZM8.50003 21H6.5C6.50273 19.6735 5.97487 18.401 5.034 17.466C4.09908 16.5249 2.82652 15.9971 1.5 16V14C3.35776 13.9944 5.14056 14.7322 6.451 16.049C7.76773 17.3595 8.50554 19.1423 8.50003 21ZM4.5 21H1.5V18C3.15685 18 4.5 19.3431 4.5 21Z"
                                fill="#7ABDEA" />
                        </svg><span>Learn about how to be more eco-friendly with <span
                                class="text-[#7ABDEA] font-semibold">our
                                resources</span></span><svg class="inline ml-1" width="13" height="12" viewBox="0 0 13 12"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.2507 12H2.00073C1.17231 12 0.500732 11.3284 0.500732 10.5V2.25C0.500732 1.42157 1.17231 0.75 2.00073 0.75H5.00073V2.25H2.00073V10.5H10.2507V7.5H11.7507V10.5C11.7507 11.3284 11.0792 12 10.2507 12ZM6.27573 7.28025L5.21823 6.21975L9.93798 1.5H7.25073V0H12.5007V5.25H11.0007V2.56125L6.27573 7.28025Z"
                                fill="#7ABDEA" />
                        </svg>
                    </span>
                </div>

                <div class="w-full grid grid-cols-9 gap-4">
                    <div v-for="subtopic in spec.subtopics" class="lg:col-span-3 col-span-9">
                        <div class="border-4 border-[#47495F] rounded-2xl w-full p-10 flex flex-col h-full justify-between">
                            <div :class="`rounded-[125px] px-4 py-1 ${chipColor[subtopic.mood]} mb-6 w-fit`">
                                {{subtopic.moodText}}
                            </div>
                            <h4 class="mb-10">{{ subtopic.title }}</h4>
                            <h3 class="mb-10 !font-light italic">{{ subtopic.quote }}</h3>
                            <p class="p2-light mb-16">{{ subtopic.description }}</p>

                            <template>
                                <p class="text-[#0A2A9E]">Improve to gain audit point by: </p>

                                <div class="rounded-[100px] px-4 py-1 bg-[#0A2A9E1A] w-min">
                                    <span class="text-[#0A2A9E]">+{{ subtopic.possibleGain }}%</span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script>

import { bgColor, textColor, chipColor } from './styles.js'

export default {
    props: {
        spec: {
            type: Object
        }
    },
    data: () => {
        return {
            bgColor, textColor, chipColor
        }
    }
}
</script>
