<template>
  <div class="pt-[128px] w-full">
    <div :class="`flex flex-col mb-[96px] ${styles.ppage}`">
      <h2 class="text-center mb-4">Our current projects</h2>
      <p class="text-center">We partner with innovators around the world to design impactful solutions</p>
    </div>
    <template v-for="(project, index) in projects">
      <Project v-bind="project" class="lg:mb-[160px] mb-[80px]" />
    </template>
  </div>
</template>

<script>
import Project from "./project.vue";
import styles from "./styles";

export default {
  components: {
    Project,
  },
  props: {
    obj: {
      type: Object,
    },
  },
  data() {
    return {
      styles: styles,
      projects: [
        {
          image_url: this.obj.sustainable_tourism_skill,
          icon_url: this.obj.box_search,
          title: "Sustainable Tourism Certification Platform",
          text: "An online assessment and sustainability management system based on the Global Sustainable Tourism Council (GSTC) criteria. It helps travel companies identify gaps in their ESG strategy and provides up-skilling and action plans to earn sustainable tourism certification.",
          button: {
            text: "Take A Look",
            url: this.obj.take_a_look_url,
          },
        },
        {
          image_url: this.obj.tour_op,
          icon_url: this.obj.code,
          reverse: true,
          title: "Simple, Digital, Sustainable Tour Operation System",
          text: "A suite of software tools that help tour operators manage their sustainability initiative, set goals, and KPIs. It streamlines the vetting process to ensure vendors and suppliers meet the operators' sustainability standards and policies.",
        },
      ].map((value) =>
        Object.assign({}, { ...value, btn_icon: this.obj.btn_icon })
      ),
    };
  },
};
</script>