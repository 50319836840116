<template>
  <div>
    <div class="title">
      <div class="google">
        <img :src="icon" alt="icon" class="pr-2">
        <p>Google Contact</p>
      </div>
      <div>
        <p>{{ selectedCount }} People Selected</p>
      </div>
    </div>
    <div class="contact-list">
      <div v-for="(user, index) in users" class="my-4" :key="index">
        <div class="flex">
          <input type="checkbox" :name="spec.name" v-model="user.isSelected" @change="updateSelectedCount"
            :value="user.email">
          <img :src="user.photo" alt="icon" width="48" height="48" class="mx-4 w-12 h-12 rounded-full">
          <div>
            <p>{{ user.name }}</p>
            <p class="text-gray-400">{{ user.email }}</p>
          </div>
        </div>
        <hr class="line">
      </div>
    </div>
  </div>
</template>

<script>
import googleIcon from '../../../../assets/images/google-icon.png'

export default {
  props: {
    spec: { type: Object, required: true },
  },
  data() {
    return {
      icon: googleIcon,
      users: [],
      selectedCount: 0,
    }
  },
  created() {
    this.users = Object.values(this.spec.contacts);
  },
  methods: {
    updateSelectedCount() {
      this.selectedCount = this.users.filter(user => user.isSelected).length;
    }
  }
}
</script>

<style scoped>
.contact-list {
  max-height: 300px;
  overflow-y: auto;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.google {
  display: flex;
  justify-content: space-between;
}

.line {
  margin: 16px 0;
  width: 100%;
}
</style>
