<template>
  <v-card outlined :class="spec.responsiveCols ? 'mb-5' : 'mb-3'" :style="columnStyles()">
    <v-list-item class="box" :href="$href()" @click="$onClick()">
      <!-- <div v-if="spec.imageUrl" class="left-panel">
        <glib-component :spec="{ view: 'avatar', url: spec.imageUrl }" />
      </div>     -->

      <v-list-item-avatar v-if="$type.isString(spec.imageUrl)" :tile="!spec.avatar" :size="75" class="left-panel">
        <img :src="spec.imageUrl" />
      </v-list-item-avatar>

      <v-list-item-content>
        <glib-component :spec="{ view: 'h4', text: spec.title }" />
        <glib-component :spec="{ view: 'spacer', height: 2 }" />
        <glib-component :spec="{ view: 'h5', text: spec.subtitle, styleClasses: ['small'] }" />
        <glib-component class="my-1" :spec="{
    view: 'h5',
    text: spec.subsubtitle,
    styleClasses: ['small']
  }" />
        <div v-if="data.description" class="my-1">
          <glib-component :spec="{ view: 'p', text: data.description }" />
        </div>
        <div>
          <template v-for="(item, chip_index) in chips" :key="chip_index">
            <v-chip class="ma-1" :class="item.styleClasses" :color="item.color" medium>
              {{ item.text }}
            </v-chip>
          </template>
        </div>

        <!-- NOTE: Experimental -->
        <panels-responsive :spec="spec.accessory" />
      </v-list-item-content>

      <template v-for="(item, right_button_index) in spec.rightButtons" :key="right_button_index">
        <common-button :spec="buttonSpec(item)" :disabled="$isBusy" />
      </template>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
    index: { type: Number, required: true },
    responsiveCols: { type: Number, default: () => 0 },
  },
  data() {
    return {
      data: {},
      chips: [],
      horizontalSpacingMultiplier: 1,
    };
  },
  computed: {
    smAndAboveOnly() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return false;
        default:
          return true;
      }
    },
    verticalSpacing() {
      return 100 - (this.responsiveCols - 1) * this.horizontalSpacingMultiplier;
    },
  },
  methods: {
    $ready() {
      this.data = this.spec.data || {};

      this.chips = (this.spec.chips || []).map((item) => {
        var color = null;
        this.$type.ifArray(item.styleClasses, (classes) => {
          for (const val of ["success", "info", "warning", "error"]) {
            if (classes.includes(val)) {
              color = val;
            }
          }
        });
        return Object.assign({}, item, { color: color });
      });
    },
    buttonSpec(item) {
      return Object.assign({}, item, {
        view: "button-v1",
        styleClasses: item.text ? ["text"] : ["icon"],
      });
    },
    columnStyles() {
      if (this.smAndAboveOnly && this.responsiveCols) {
        return `
          width: ${this.verticalSpacing / this.responsiveCols}%;
          margin-left: ${this.index % this.responsiveCols != 0
            ? this.horizontalSpacingMultiplier
            : 0
          }%;
          float: left;
        `;
      }
    },
  },
  // computed: {
  //   iconColor: function () {
  //     return '#FFF'
  //   },
  //   iconBackgroundColor: function () {
  //     return '#EEE'
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.box {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
}

.left-panel {
  margin-right: 12px;
}
</style>
