/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import { Vue, settings, useGlibErrorReporter } from "glib-web/index.js";
import '../../assets/stylesheets/application.css';

// settings.gtagId = 'UA-146876849-1'

// Talent Basket -> Company Site
// settings.gtagId = 'UA-84292308-1'

// talentbasket.com -> Talent Basket
// settings.gtagId = "UA-3931220-2";

settings.themes = {
  light: {
    dark: false,
    colors: {
      primary: "#0A2A9E",
      warning: "#FFBB34",
      error: "#FF3548",
      info: "#33B5E7",
      success: "#1F845A",
      green: "#FF5D6D"
    }
  }
};

useGlibErrorReporter();

import CommonTemplate from "./custom/templates/common.vue";
Vue.component("template-standard", CommonTemplate);
import HeaderLayoutTemplate from "./custom/templates/layouts/header.vue";
import FooterLayoutTemplate from "./custom/templates/layouts/footer.vue";
Vue.component("template-layouts-header", HeaderLayoutTemplate);
Vue.component("template-layouts-footer", FooterLayoutTemplate);

import TermsIntroTemplate from "./custom/templates/intros/terms.vue";
Vue.component("template-intros-terms", TermsIntroTemplate);

import PrivacyPolicyIntroTemplate from "./custom/templates/intros/privacyPolicy.vue";
Vue.component("template-intros-privacyPolicy", PrivacyPolicyIntroTemplate);

import FAQIntroTemplate from "./custom/templates/intros/faq.vue";
Vue.component("template-intros-faq", FAQIntroTemplate);

import PlatformFooterLayoutTemplate from "./custom/templates/layouts/platformFooter.vue";
Vue.component("template-layouts-platformFooter", PlatformFooterLayoutTemplate);

import Stepper from "./custom/templates/layouts/stepper.vue";
Vue.component("template-layouts-stepper", Stepper);

import GoogleIdButton from "./custom/templates/googleIdButton.vue";
Vue.component("template-google-id-button", GoogleIdButton);

import ProfileHeader from "./custom/templates/components/profile-header.vue";
Vue.component("template-profile-header", ProfileHeader);

import MenuButton from "@/entrypoints/custom/templates/menuButton.vue";
Vue.component("template-menu-button", MenuButton);

import TBLanding from './custom/templates/tb-landing/index.vue';
Vue.component("template-tb-landing", TBLanding);

import HotelZero from './custom/templates/hotelzero/index.vue';
Vue.component("template-hotelzero", HotelZero);

import HZFooter from "./custom/templates/hotelzero/footer.vue";
Vue.component("template-footer", HZFooter);

import RichTextWithTemplate from './custom/templates/richTextWithTemplate.vue';
Vue.component("template-richTextWithTemplate", RichTextWithTemplate);

import OneScreen from './custom/templates/oneScreenQuestion.vue';
Vue.component("template-one-screen-question", OneScreen);

import Assignee from './custom/templates/assignee.vue';
Vue.component("template-assignee", Assignee);

import HotelProfile from './custom/templates/hotel-profile/index.vue';
Vue.component('template-hotel-profile', HotelProfile);

import GoogleList from './custom/templates/googleList.vue';
Vue.component("template-google-list", GoogleList);

import ExpandButton from "@/entrypoints/custom/templates/expandButton.vue";
Vue.component("template-expand-button", ExpandButton);

import CertificationFolder from "./custom/templates/certificationFolder.vue";
Vue.component("template-certification-folder", CertificationFolder);

import UploadStatus from "./custom/templates/uploadStatus.vue";
Vue.component("template-upload-status", UploadStatus);

import FieldsRadioImage from "./custom/templates/fieldsRadioImage.vue";
Vue.component("template-fields-radio-image", FieldsRadioImage);

import Carousel from "./custom/templates/carousel.vue";
Vue.component("template-carousel", Carousel);

import SimpleChecklist from "./custom/templates/simpleChecklist.vue";
Vue.component("template-simple-checklist", SimpleChecklist);

import UploadButton from "./custom/templates/uploadButton.vue";
Vue.component("template-upload-button", UploadButton);


import ButtonDate from "./custom/templates/button_date.vue";
Vue.component("template-button-date", ButtonDate);
