<template>
  <v-container class="term_wrap">
    <v-row>
      <v-col>
        <h1 class="blue_head text-center" style="margin-top: 24px;">{{ spec.heading }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="body_content" v-html="spec.introductions_title"></div>
          <div class="body_content" v-html="spec.introductions"></div>

          <div class="body_content" v-html="spec.get_information_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.get_information"></div>

          <div class="body_content" v-html="spec.information_rights_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.information_rights"></div>

          <div class="body_content" v-html="spec.third_parties_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.third_parties"></div>

          <div class="body_content" v-html="spec.protect_information_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.protect_information"></div>

          <div class="body_content" v-html="spec.other_things_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.other_things"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["spec"]
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/_colors.scss";
</style>