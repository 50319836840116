<template>
  <v-btn class="expand-button" size="32" :elevation="0" variant="flat" @click="toggleMenu">
    <v-icon :class="isMenuExpanded ? 'rotate-left' : 'rotate-right'" color="#0A2A9E" size="32">chevron_right</v-icon>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      isMenuExpanded: false,
      navElement: null,
      pageOverlay: null,
    };
  },
  computed: {
    menuIcon() {
      return this.isMenuExpanded ? 'mdi-menu-expand' : 'mdi-menu-shrink';
    },
  },
  mounted() {
    this.navElement = document.querySelector('.left-menu-hz');
    this.pageOverlay = document.querySelector('.pages-body');
  },
  methods: {
    toggleMenu() {
      this.isMenuExpanded = !this.isMenuExpanded;
      if (this.isMenuExpanded) {

        this.navElement.classList.add('left-menu-expand');
        this.navElement.classList.remove('left-menu-shrink');
        this.pageOverlay.classList.add('pages-body-overlay');

        const overlayElement = document.querySelector('.pages-body-overlay');
        overlayElement.addEventListener('click', this.closeMenu);

      } else {
        this.navElement.classList.add('left-menu-shrink');
        this.navElement.classList.remove('left-menu-expand');
        this.pageOverlay.classList.remove('pages-body-overlay');
      }
    },
    closeMenu() {
      this.isMenuExpanded = !this.isMenuExpanded;

      this.navElement.classList.add('left-menu-shrink');
      this.navElement.classList.remove('left-menu-expand');
      this.pageOverlay.classList.remove('pages-body-overlay');
    }
  },
};
</script>

<style scoped>
.rotate-left {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.rotate-right {
  transition: transform 0.3s ease;
  transform: rotate(0deg);
}
</style>
