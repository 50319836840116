<template>
  <div>
    <div class="progress-wrapper">
      <p style="color: #0A2A9E;">Hotel operation permit</p>
      <div class="progress">
        <div class="bar"></div>
        <p>0/{{Object.keys(spec).length}}</p>
      </div>
    </div>
    <div class="root">
      <div v-for="(data, index) in spec" class="page-section" :id="`section-${index}`">
        <div class="section" :style="{'background-color': data.type === 'tips' ? '#DEF2FF' :'white'}">
          <h3>{{ data.title }}</h3>
          <p>{{ data.description }}</p>
          <hr/>
          <div v-if="data.input_type == 'input'" class="input-wrapper">
            <input type="text" placeholder="Input....." v-model="data.inputValue" :name="`input-${index}`" @change="updateData(index, data.inputValue)" >
            <hr/>
          </div>
          <div class="button-area">
            <button v-if="index != 0" @click="scrollToElement(`section-${parseInt(index) - 1 }`,`section-${index}`)">Prev Section</button>
            <div v-else></div>
            <button v-if="Object.keys(spec).length != (parseInt(index) + 1)" @click="scrollToElement(`section-${parseInt(index) + 1 }`,`section-${index}`)">Next Section</button>
            <button v-else="Object.keys(spec).length != (parseInt(index) + 1)" @click="saveData(); redirectTo('http://localhost:3000/screen/1')">Finish</button>
          </div>
          <button class="continue-button" @click="saveData(); redirectTo('http://localhost:3000/screen/1')">Save and continue later</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data() {
    return {
      currentIndex: 0,
      formData: {}
    }
  },
  methods: {
    scrollToElement(id, currentElement) {
      const element = document.getElementById(id)
      if (
        this.spec[this.currentIndex].input_type === "input" &&
        !this.spec[this.currentIndex].inputValue
      ) {
        const elementInput = document.getElementById(currentElement)
        const inputElement = elementInput.querySelector('.input-wrapper');
        inputElement.classList.add('error-validation');
        inputElement.classList.add('error-border');
        setTimeout(() => {
          inputElement.classList.remove('error-validation');
        }, 700);
        return
      }
      this.currentIndex = parseInt(id.split("-")[1]);
      element.scrollIntoView({behavior: 'smooth'})
    },
    updateData(index, value) {
      this.formData[`input-${index}`] = value;
    },
    saveData(){
      const d = new Date();
      d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
      const expires = "expires="+ d.toUTCString();
      document.cookie = `formData=${JSON.stringify(this.formData)};` + expires + ";path=/";
    },
    redirectTo(url) {
      window.location.href = url;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/stylesheets/_colors.scss";

.progress-wrapper {
  display: flex; 
  padding: 16px 16px 0 16px;
  position: fixed;
  width: 100%;
  background-color: $white-color;
}

.progress {
  display: flex;

  & .bar {
    width: 116px;
    height: 16px;
    background-color: $grey-color;
    margin: 6px 8px 0 8px;
    border-radius: 24px;
  }
}

.section {
  width: 448px;
  padding: 24px;
  border-radius: 24px;
}

.button-area {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  & button {
    padding: 16px 24px;
    background-color: $primary-color-main;
    border-radius: 24px;
    color: $primary-color-dark;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
}

.page-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-wrapper {
  & input {
  width: 300px;
  height: 49px;
  background: #FFFFFF;
/* Neutrals/Grey or Stroke */
  margin: 40px 0  ;
  border: 1px solid #E6E6E6;  
  border-radius: 5px;   
  }
  & input::placeholder{
    padding-left: 16px;
  }
}

hr {
  border-color: #E6E6E6;
  border-width: 1.5px;
}

.continue-button {
  margin-top: 40px;
  color: #0A2A9E;
  width: 100%;
  text-align: center;
}

input:focus {
  outline: none;
}
.root {
  background-color: $grey-color;
  padding: 0 14px;
}

.error-validation {
  input {
    animation: shake 0.5s;
  }
}

.error-border input {
    border: 1px solid red;
}

@media only screen and (min-width: 600px) {
  .input-wrapper {
    & input {
      width: 400px;
      height: 49px;
    }
  }

  .root{
    padding: 0;
  }
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}
</style>
