<template>
  <v-app>
    <v-content class="faq_wrap">
      <v-container>
        <v-row>
          <v-col>
            <h1 class="faq_head blue_head">{{ spec.heading }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs v-model="tab" background-color="white" class="chip">
              <v-tab
                v-for="item in filteredList"
                v-bind:key="item.type_id"
                @click="getTab(item.type_id)"
                >{{ item.type }}</v-tab
              >
              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="item in filteredList"
                  v-bind:key="item.type_id"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <!-- <v-row class="justify-center search_box">
                    <v-col cols="12">
                      <div class="search_box_wrap">
                        <v-text-field
                          label="Start typing to find answers...."
                          v-model="search"
                          solo
                        ></v-text-field>
                        <i class="material-icons">search</i>
                      </div>
                    </v-col>
                  </v-row> -->
                  <v-row class="justify-center">
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-for="item_1 in item.topics"
                      v-bind:key="item_1.topic_id"
                    >
                      <div class="faq-box">
                        <a :href="item_1.link">
                          <img :src="item_1.picture_url" class="box-circle">
                          <h3>{{ item_1.title }}</h3>
                          <p>{{ item_1.detail }}</p>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <v-content class="get-in-touch">
      <v-container>
        <v-row>
          <v-col>
            <h4 class="pink_head heading_border_btm">
              <span>{{ spec.contact.heading }}</span>
            </h4>
            <h2 class="blue_head">{{ spec.contact.sub_heading }}</h2>
            <p>{{ spec.contact.description }}</p>
            <a
              v-if="spec.contact.button_2"
              :href="spec.contact.link_2"
              class="default_btn_2 default_btn"
              >{{ spec.contact.button_2 }}</a
            >
            <a
              v-if="spec.contact.button_1"
              :href="spec.contact.link_1"
              class="default_btn"
              >{{ spec.contact.button_1 }}</a
            >
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  props: ['spec'],
  data() {
    return {
      tab: null,
      search: '',
      topicList: this.spec.faq_topics,
      topicList_filtered: [],
    };
  },
  computed: {
    filteredList() {
      this.topicList_filtered.length = 0;
      for (let i = 0; i < this.topicList.length; i++) {
        const filteredObject = {};
        filteredObject['type_id'] = this.topicList[i].type_id;
        filteredObject['type'] = this.topicList[i].type;
        filteredObject['topics'] = this.topicList[i].topics.filter(
            (topicData) => {
              return topicData.title
                  .toLowerCase()
                  .includes(this.search.toLowerCase());
            },
        );
        this.topicList_filtered.push(filteredObject);
      }

      return this.topicList_filtered;
    },
  },
  methods: {
    getTab: function() {
      this.search = '';
    },
  },
};
</script>

