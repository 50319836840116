<template>
  <div class="footer">
    <!-- TODO: Avoid hardcoding year -->
    Copyright Talent Basket © {{ new Date().getFullYear() }}
    <div class="float-right">
      <template v-for="(item, index) in spec.rightButtons">
        <v-btn v-if="item.text" text :href="$href(item)" :key="index">{{item.text}}</v-btn>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['spec'],
  methods: {
    $ready() {},
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/_breakpoints.scss";
@import "../../../../../assets/stylesheets/_colors.scss";

.footer {
  border-top: #cccccc 1px solid;
  a {
    text-decoration: none;
    &:hover {
      color: $primary-color-dark;
    }
  }
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 84px;
  padding-right: 84px;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .footer {
    text-align: center;
    padding-top: 34px;
    padding-bottom: 34px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .float-right {
    text-align: center;
    float: none !important;
  }
}
</style>
