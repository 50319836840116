<template>
    <div class="flex flex-col items-center justify-center w-full py-[80px] px-4">
        <h2>{{ spec.title }}</h2>
        <p class="p2-light mb-10">{{ spec.title_desc }}</p>

        <div class="grid grid-cols-12 gap-4">
            <div v-for="item in spec.items" class="lg:col-span-4 col-span-12">
                <a :href="item.url">
                    <div :class="`flex flex-col w-full border-4 border-[#47495F] rounded-2xl ${item.bgColor} p-10 hover:bg-slate-50`">
                        <img class="w-[64px] h-[64px] mb-6" :src="item.imageUrl" />
                        <p class="!font-bold mb-6">{{ item.title }}</p>
                        <p>{{ item.description }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        spec: {
            type: Object
        },
        currentUser: {
            type: Object
        }
    }
}
</script>