<template>
  <div class="carousel">
    <transition name="fade" mode="out-in">
      <div class="carousel-content" :key="currentSlide">
        <img :src="slides[currentSlide].image" alt="Certification Assessment Image"
          :width="slides[currentSlide].imageWidth" />
        <div class="carousel-title">{{ slides[currentSlide].title }}</div>
        <div class="carousel-description">{{ slides[currentSlide].description }}</div>
      </div>
    </transition>
    <div class="carousel-indicators">
      <div v-for="(slide, index) in slides" :key="index" class="carousel-indicator"
        :class="{ active: index === currentSlide }" @click="currentSlide = index"></div>
    </div>
  </div>
  <div class="carousel-buttons">
    <div v-if="currentSlide !== 0" class="carousel-button back" @click="previousSlide">
      <span>Back</span>
      <v-icon icon="chevron_left" size="16"></v-icon>
    </div>
    <div v-if="currentSlide !== slides.length - 1" class="carousel-button next" @click="nextSlide">
      <span>Next</span>
      <v-icon icon="chevron_right" size="16"></v-icon>
    </div>
    <div v-if="currentSlide + 1 === slides.length" class="carousel-button start" @click="getStarted">
      <span>Get Started</span>
      <v-icon icon="chevron_right" size="16"></v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data() {
    return {
      currentSlide: 0,
      slides: this.spec.slides
    };
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    previousSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },
    getStarted() {
      GLib.action.execute({ action: 'dialogs/close', url: this.spec.url }, {});
    },
  },
};
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.carousel-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.carousel-content img {
  object-fit: contain;
  margin-bottom: 20px;
}

.carousel-description {
  font-size: 14px;
  margin-bottom: 20px;
}

.carousel-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.carousel-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
  margin-top: 20px;

  display: flex;
  align-items: center;
  gap: 8px;

  &.back {
    flex-direction: row-reverse;
    background-color: transparent;
    color: #0A2A9E;

    .v-icon,
    span {
      color: #0A2A9E;
    }
  }

  &.next {
    background-color: #EAF5FD;
    border-radius: 100px;
    color: #0A2A9E;

    .v-icon,
    span {
      color: #0A2A9E;
    }
  }

  &.start {
    background-color: #0A2A9E;
    border-radius: 100px;
    color: #fff;

    .v-icon,
    span {
      color: #fff;
    }
  }
}

.carousel-button:hover {
  background-color: #d4d6d8;
}

.carousel-indicators {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 5px;
}

.carousel-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.2s;
}

.carousel-indicator.active {
  background-color: #2962ff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
