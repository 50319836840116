<template>
  <div class="w-full">
    <!-- <Header /> -->
    <OverallScore :spec="spec.overallScore" />
    <template v-for="topicScore in spec.topicScores">
      <TopicScore :spec="topicScore" />
    </template>
    <WhatNext :spec="spec.whatNext" :currentUser="spec.overallScore.currentUser" />
    <Signup :spec="spec.signup" />
    <Footer :spec="spec.footer" />
  </div>
</template>

<script>
import OverallScore from "./overallScore.vue";
import TopicScore from "./topicScore.vue";
import WhatNext from "./whatNext.vue";
import Signup from "./signup.vue";
import Header from "./header.vue";
import Footer from "./footer.vue";


export default {
  props: {
    spec: {
      type: Object
    }
  },
  components: {
    OverallScore,
    TopicScore,
    WhatNext,
    Signup,
    Header,
    Footer
  },
};
</script>

<style></style>