<template>
  <div :style="$styles()">
    <div class="bg-[#EAF5FD] px-[6px] py-[6px] rounded-full cursor-pointer flex items-center"
      @click="() => inputEl.click()">
      <v-icon size="20" icon="file_upload" color="primary"></v-icon>
    </div>
    <input ref="inputEl" :name="props.spec.name" type="file" multiple style="display: none;"
      @change="(e) => handleUpload(e)" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { setBusyWhenUploading, uploadFiles, submitOnAllUploaded } from 'glib-web/components/composable/upload';
import { computed } from 'vue';
import { useFileUtils } from 'glib-web/components/composable/file';
import { useGlibInput } from 'glib-web/components/composable/form';

const { signedIds } = useFileUtils();

const props = defineProps(['spec']);
const files = ref({});
const inputEl = ref(null);
const url = ref(props.spec.url);
const formData = computed(() => {
  return { [props.spec.name]: signedIds(files) };
});

setBusyWhenUploading({ files });

submitOnAllUploaded({ files, url, formData, actionName: 'http/patch' });

useGlibInput({ props });

function handleUpload(event) {
  uploadFiles({
    droppedFiles: event.target.files,
    container: inputEl.value,
    files,
    spec: props.spec
  });
  inputEl.value.value = null;
  event.stopPropagation();
}
</script>