<template>
  <div class="mx-auto w-full max-w-[960px] relative">
    <div class="bg-white w-full rounded-[24px] pt-[200px]">
      <div v-if="spec.hotel.bannerEdit"
        class="bg-[#EAF5FD] p-[12px] absolute top-0 right-0 m-6 z-10 rounded-full cursor-pointer"
        @click="action(spec.hotel.bannerEdit)">
        <v-icon icon="edit" color="primary" size="24"></v-icon>
      </div>
      <div class="absolute top-0 h-[280px] w-full bg-[#e6e6e6] rounded-t-[24px] overflow-hidden">
        <template v-if="spec.hotel.banner">
          <glib-component
            :spec="{ view: 'views/image-v1', url: spec.hotel.banner, fit: 'crop', width: 'matchParent' }"></glib-component>
        </template>
      </div>

      <div class="absolute top-[280px] px-lg-10 pb-lg-10 px-2 pb-4 w-full bg-white rounded-b-[24px]">
        <div class="rounded-[24px] w-full bg-white box-shadow mt-[-80px] py-lg-6 py-4 px-lg-10 px-4">

          <div class="flex flex-md-nowrap flex-wrap w-full items-center justify-between mb-10 gap-4 overflow-hidden">
            <div class="flex gap-6">
              <div
                :class="`rounded-[16px] ${spec.assets.imageLogo ? '' : 'p-6 bg-primary'} w-[80px] h-[80px] overflow-hidden`">
                <glib-component v-if="spec.assets.imageLogo"
                  :spec="{ view: 'views/image-v1', url: spec.assets.imageLogo, height: 'matchParent', fit: 'crop' }"></glib-component>
                <v-icon v-else icon="corporate_fare" color="white" size="32"></v-icon>
              </div>
              <div>
                <div class="flex flex-nowrap mb-2 items-center gap-2 justify-between">
                  <h2 class="truncate max-sm:max-w-[150px]">{{ spec.hotel.name }}</h2>
                  <div class="md:hidden block" v-if="spec.hotel.moreOptions">
                    <Option :more-options="moreOptions"></Option>
                  </div>
                </div>
                <p>{{ spec.hotel.type }}</p>
              </div>
            </div>

            <div class="flex items-center gap-2">
              <div :class="`${styles.chip} flex items-center gap-2`">
                <span class="text-[14px]">{{ spec.hotelLevel.text }}</span>
                <img :src="spec.hotelLevel.icon" width="24" height="24" />
              </div>

              <div class="hidden md:block" v-if="spec.hotel.moreOptions">
                <Option :more-options="moreOptions"></Option>
              </div>
            </div>
          </div>

          <div
            class="bg-[#0a2a9e1a] rounded-[16px] px-8 py-6 flex md:flex-row flex-col flex-nowrap justify-md-space-around justify-start mb-10 gap-4">
            <div v-for=" info in spec.general " class="flex-col justify-center lg:w-[216px] md:w-[194px]" :key="info">
              <div class="flex gap-2 items-center">
                <v-icon :icon="info.icon" size="24" color="primary"></v-icon>
                <h4 class="text-color-primary">{{ info.title }}</h4>
              </div>
              <p>{{ info.text || '-' }}</p>
            </div>
          </div>

          <div class="bg-[#F2F5F7] py-6 px-8 rounded-[16px] mb-10">
            <div class="flex items-center flex-nowrap justify-between mb-6">
              <div class="flex items-center gap-2">
                <img :src="spec.overview.image" width="24" height="24" />
                <h4>{{ spec.overview.title }}</h4>
              </div>

              <v-icon v-if="spec.overview.edit" icon="edit" color="primary" size="24"
                @click="action(spec.overview.edit)" />
            </div>

            <p class="mb-6">{{ spec.overview.description || '-' }}</p>

            <div class="flex flex-wrap gap-6 mb-6">
              <div v-for=" item in spec.overview.items " class="w-[336px]" :key="item">
                <p class="mb-2">{{ item.title }}</p>
                <p>{{ item.subtitle || '-' }}</p>
              </div>
            </div>

            <p class="mb-2">{{ spec.overview.chip.title }}</p>
            <div class="flex flex-wrap gap-2">
              <div v-if="spec.overview.chip.items.length > 0" :class="styles.chip"
                v-for="standard in spec.overview.chip.items" :key="standard"><span class="text-[14px]">{{ standard
                }}</span></div>
              <div v-else>-</div>
            </div>
          </div>

          <Section v-bind="spec.sustainability" />

          <div class="px-6 mb-10">
            <div class="flex justify-between items-center flex-wrap mb-6 gap-2">
              <div class="flex items-center gap-2">
                <img :src="spec.whatWeOffer.image" width="24" height="24" />
                <h4>{{ spec.whatWeOffer.title }}</h4>
              </div>
              <div class="rounded-[16px] px-6 py-4 border-[#E6E6E6] border flex flex-wrap gap-4">
                <div v-for=" icon in spec.whatWeOffer.icons " class="flex gap-1">
                  <img :src="icon.image" width="16" height="16" />
                  <span class="text-[11px]">{{ icon.text }}</span>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap gap-4 justify-between">
              <div class="flex items-center gap-1 max-w-[348px] w-full" v-for=" item in spec.whatWeOffer.items">
                <img :src="item.icon" width="24" height="24" />
                <span :class="`text-color-light ${item.action ? 'cursor-pointer hover:underline' : ''}`"
                  @[item.action&&`click`]="action(item.action)">{{ item.label }}</span>
              </div>
            </div>

          </div>

          <Section v-bind="spec.whatWeFindHard" />
          <Section v-bind="spec.ourGoal" />

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Section from './section.vue';
import Option from './option.vue';
import { computed } from 'vue';

const { spec } = defineProps({ spec: Object });

const moreOptions = computed(() => {
  return spec.hotel.moreOptions.map((option) => {
    return { text: option.text, action: () => action(option.action) }
  })
})

const styles = {
  chip: 'px-4 py-[6px] rounded-[100px] text-color-primary bg-[#0a2a9e1a]'
};

function action(spec) {
  return GLib.action.execute(spec, this || {})
}
</script>

<style scoped="true">
.box-shadow {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
}
</style>