<template>
  <div
    :id="spec.current_route"
    class="site-header mobile_header"
    :class="spec.header_type"
  >
    <div class="mobile_menu" :class="{ active: isActiveMenu }">
      <div
        class="mobile_menu_icon"
        :class="{ change: isActive }"
        @click="menuToggle"
      >
        <img :src="spec.menu_icon" alt="menu" class="open_menu_icon" />
        <img :src="spec.menu_close_icon" alt="menu" class="close_menu_icon" />
      </div>
      <ul class>
 
        <li
          v-for="item in spec.mobile_login_menu"
          :key="item.id"
          class="mobile_login_menu"
        >
          <a :href="item.link">{{ item.title }}</a>
        </li>

        <li
          v-if="spec.contact_info.us"
          class="mobile_menu_contact mobile_menu_contact_1"
        >
          US:
          <a :href="'tel:' + spec.contact_info.us">
            {{ spec.contact_info.us }}
          </a>
        </li>
        <li v-if="spec.contact_info.taiwan" class="mobile_menu_contact">
          Taiwan:
          <a :href="'tel:' + spec.contact_info.taiwan">
            {{ spec.contact_info.taiwan }}
          </a>
        </li>
        <li v-if="spec.contact_info.email" class="mobile_menu_email">
          <a :href="'mailto:' + spec.contact_info.email">
            {{ spec.contact_info.email }}
          </a>
        </li>
      </ul>
    </div>
    <div
      class="mobile_menu_icon"
      :class="{ change: isActive }"
      @click="menuToggle"
    >
      <img :src="spec.menu_icon" alt="menu" class="open_menu_icon" />
      <img :src="spec.menu_close_icon" alt="menu" class="close_menu_icon" />
    </div>
    <div class="site-logo">
      <v-progress-circular
        v-if="$root.vueApp.indicator"
        indeterminate
      ></v-progress-circular>
      <a v-else :href="spec.home_link" class="dark-logo">
        <img :src="spec.darkLogo" :alt="spec.site_name" />
      </a>
    </div>
    <div v-if="spec.login" class="text-right site-menu">
      <a :href="spec.login.link" class="right-button">{{ spec.login.text }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["spec"],
  data() {
    return {
      isActive: false,
      isActiveMenu: false
    };
  },
  computed: {
    photo() {
      return this.spec.photo;
    },
    leftStyle() {
      const style = {};
      if (this.spec.header_type == "half") {
        style["backgroundColor"] = "#272551";
      }
      return style;
    }
  },
  methods: {
    menuToggle: function() {
      this.isActive = !this.isActive;
      this.isActiveMenu = !this.isActiveMenu;
    }
  }
};
</script>

<style lang="scss" scoped>
.column {
  padding-top: 37px;
  padding-bottom: 37px;
}
.site-logo {
  width: calc(100% - 130px);
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
}
.site-logo img {
  max-width: 115px;
}
.v-application .right-button {
  color: #0A2A9E;
}
</style>
