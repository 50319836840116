<template>
  <div>
    <header-desktop :spec="spec" />
    <header-mobile :spec="spec" />
  </div>
</template>

<script>
import HeaderDesktop from './headerDesktop.vue';
import HeaderMobile from './headerMobile.vue';

export default {
  components: {
    'header-desktop': HeaderDesktop,
    'header-mobile': HeaderMobile,
  },
  props: ['spec'],
  computed: {
    photo() {
      return this.spec.photo;
    },
    leftStyle() {
      const style = {};
      if (this.spec.header_type == 'half') {
        style['backgroundColor'] = '#272551';
      }
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  padding-top: 37px;
  padding-bottom: 37px;
}
</style>
