<template>
  <div class="w-full bg-[#F2F5F7] py-[80px] flex justify-center items-center px-4">
    <div class="w-full grid grid-cols-12 gap-4 items-center">
      <div class="lg:col-start-2 lg:col-span-4 col-span-12">
        <img class="w-full" :src="spec.imageUrl" />
      </div>
      <div></div>
      <div class="lg:col-span-5 col-span-12">
        <h2 class="mb-10">{{ spec.signup_title }}</h2>

        <p class="p3-regular mb-10">
          {{ spec.signup_desc }}
        </p>

        <a v-if="spec.url">
          <div @click="onSignupClicked()" class="bg-[#0A2A9E] rounded-[100px] px-6 py-3 text-white w-fit cursor-pointer">
            {{ spec.signup_button }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    spec: {
      type: Object,
    },
  },
  methods: {
    onSignupClicked() {
      const prop = {
        
        action: "dialogs/open-v1",
        url: this.spec.url,
      };
      window.GLib.action.execute(prop, this);
    },
  },
};
</script>