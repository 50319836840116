<template>
  <div ref="container"
    class="cursor-pointer rounded-[16px] border-{#E6E6E6] py-4 px-6 border-[#1f845a40] border flex flex-col justify-start w-[185px] h-[114px] gap-2 pointer-eve"
    :key="spec.folder.id">
    <div class="relative">
      <input ref="fileSelect" type="file" multiple style="display: none">
      <img :src="spec.folder.icon" style="width: 24px; height: 24px; margin-bottom: 8px;" />
      <div class="font-normal text-sm leading-6 max-w-[120px]">{{ spec.folder.title }}</div>
      <v-tooltip text="File(s) Uploaded">
        <template v-slot:activator="{ props }">
          <div v-bind="props"
            class="absolute top-0 right-0 bg-[#0A2A9E] flex justify-center gap-1 items-center rounded-xl px-2 py-1"
            v-if="spec.folder.total_files >= 1">
            <v-icon icon="description" class="text-white text-xs" size="16"></v-icon>
            <p class="text-white text-xs">{{ spec.folder.total_files }}</p>
          </div>
        </template>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useDropUpload } from "glib-web/components/composable/dropable";
import * as uploader from "glib-web/components/composable/upload_delegator";

const component = defineComponent({
  props: { spec: Object },
  setup(props) {
    const { spec } = props;

    const container = ref(null);
    const fileSelect = ref(null);
    const files = ref({});

    const obj = Object.assign({}, spec, {
      onDragStyle: ['bg-[#47495F1A]']
    });

    useDropUpload({
      files,
      container,
      fileSelect,
      spec: obj,
      uploader
    });


    return { files, container, fileSelect };
  }
});

export default component;
</script>