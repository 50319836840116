<template>
  <div class="site-header desktop_header" :class="colorMode">
    <v-row :id="spec.current_route" no-gutters>
      <v-col :lg="9" :md="9" :sm="9" cols="12" class="column pl-10">
        <div>
          <div class="site-logo">
            <v-progress-circular
              v-if="$root.vueApp.indicator"
              indeterminate
            ></v-progress-circular>
            <div v-else class="header_left">
              <a
                v-if="spec.header_type == 'dark'"
                :href="spec.home_link"
              >
              </a>
              <a
                v-if="spec.header_type == 'light' || spec.header_type == 'half'"
                :href="spec.home_link"
                class="light-logo"
              >
                <img :src="spec.lightLogo" :alt="spec.site_name" />
              </a>
             
            </div>
          </div>
        </div>
      </v-col>

      <v-col :lg="3" :md="3" :sm="3" cols="12" class="column header_right pr-5">
        <div class="site-menu center_menu">
          <a
            v-if="spec.login && spec.header_login_type == 'primary'"
            :href="spec.login.link"
            class="text_primary text_normal mr-5"
            >{{ spec.login.text }}</a
          >
          <a
            v-if="spec.login && spec.header_login_type == 'secondary'"
            :href="spec.login.link"
            class="text_secondary text_normal mr-5"
            >{{ spec.login.text }}</a
          >
          <a
            v-if="spec.sign_up && spec.header_login_type == 'primary'"
            v-ripple
            :href="spec.sign_up.link"
            class="default_btn btn_primary"
            >{{ spec.sign_up.text }}</a
          >
          <a
            v-if="spec.sign_up && spec.header_login_type == 'secondary'"
            v-ripple
            :href="spec.sign_up.link"
            class="default_btn btn_secondary"
            >{{ spec.sign_up.text }}</a
          >
          <!-- <v-btn
            v-if="photo"
            :key="index"
            icon
            :href="photo.link"
            @click="$onClick($event, photo)"
          >
            <v-avatar :key="index">
              <img :src="photo.imageUrl" />
            </v-avatar>
          </v-btn> -->
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["spec"],
  data() {
    return {};
  },
  computed: {
    photo() {
      return this.spec.photo;
    },
    colorMode() {
      if (this.spec.header_type == "half") {
        switch (this.$vuetify.breakpoint.name) {
          case "sm":
          case "md":
            return "light";
        }
      }
      return this.spec.header_type;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../../../app/assets/stylesheets/_colors.scss";

.column {
  padding-top: 37px;
  padding-bottom: 37px;
}
.site-logo {
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.site-logo img {
  max-width: 115px;
}
.header_left {
  display: flex;
  align-items: center;
}
.header_right {
  display: flex;
  justify-content: flex-end;
}
.desktop_header .header_right a.default_btn.btn_primary {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  color: white;
  background-color: $primary-color-dark !important;
  border-radius: 4px;
}
.desktop_header .header_right a.default_btn.btn_secondary,
.desktop_header .header_right a.default_btn.btn_secondary:hover {
  color: white;
  background-color: #f2b805;
  border-color: #f2b805;
  font-weight: bold !important;
  border-radius: 4px;
}
.desktop_header .header_right a.text_primary {
  color: #0A2A9E;
}
.desktop_header .header_right a.text_secondary {
  color: white;
}
.desktop_header .header_right a.text_normal {
  font-weight: normal !important;
}
.desktop_header .header_right a.default_btn.btn_primary,
.desktop_header .header_right a.default_btn.btn_primary:hover {
  color: white;
  border-color: $primary-color-dark;
}
ul.main-menu {
  padding: 0;
}
</style>
