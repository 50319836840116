
<template>
    <div class="w-full mb-[80px] shadow-xl">
        <div class="flex flex-col w-full justify-center items-center py-[80px] text-[#494949] px-4">
            <h1 class="text-center">{{ spec.assessmentTitle }}</h1>
            <p class="p2-light text-center">{{ spec.assessmentName }}</p>
        </div>

        <div class="flex w-full bg-[#0A2A9E] py-[80px] justify-center items-center px-4">
            <div class="grid grid-cols-10 lg:w-[1120px] w-full items-center gap-6">
                <div class="lg:col-span-3 col-span-10">
                    <div class="flex w-full justify-center">
                        <v-progress-circular :size="300" :rotate="270" :width="30" :model-value="spec.score"
                            color="#FFD146">
                            <span class="text-6xl font-extrabold">{{ spec.score }}%</span>
                        </v-progress-circular>
                    </div>
                </div>
                <div class="lg:col-span-7 col-span-10">
                    <div class="w-full">
                        <h1 class="text-white">{{ spec.title }}</h1>
                        <p class="p3-regular text-white">{{ spec.description }}</p>
                    </div>
                    <div class="flex pt-6">
                        <a v-if="spec.shareUrl" @click="onShareClicked()" class="mr-4">
                            <div class="share-button">
                                {{ spec.shareResult }}
                            </div>
                        </a>
                        <a v-if="!spec.currentUser" @click="onSignupClicked()">
                            <div class="signup">{{ spec.signUpFree }}</div>
                        </a>
                        <a v-else :href="spec.dashboardUrl">
                            <div class="signup">{{ spec.viewDashboard }}</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        spec: {
            type: Object
        }
    },
    methods: {
        onShareClicked() {
            const prop = {
                
                action: "dialogs/open-v1",
                url: this.spec.shareUrl
            };
            window.GLib.action.execute(prop, this)
        },
        onSignupClicked() {
            const prop = {
                
                action: "dialogs/open-v1",
                url: this.spec.signupUrl
            };
            window.GLib.action.execute(prop, this)
        }
    }
}
</script>

<style>
.share-button {
    font-size: 16px;
    display: flex;
    padding: 10px 24px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* Neutrals/White */

    border: 1px solid #FFFFFF;
    border-radius: 100px;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
}

.signup {
    /* Auto layout */
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 4px;

    /* Hotelzero/Neutral/Blue Background */

    background: #EAF5FD;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    color: #0A2A9E;
    border-radius: 100px;
    cursor: pointer;
}
</style>