<template>
  <div width="100%">
    <div class="flex items-center">
      <v-icon icon="drag_indicator" size="24" style="cursor: pointer"></v-icon>
      <v-text-field class="text-[#47495F]" type="text" height="28" placeholder="Add Target Goal" :variant="null"
        v-model="spec.subtitle" :name="spec.title"></v-text-field>
    </div>
    <v-divider opacity="100" color="#E6E6E6" width="100%"></v-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ['spec']
});
</script>