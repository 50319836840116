<template>
  <div style="width: 100%">
    <v-row no-gutter>
      <v-col md="12">
        <v-select label="Use our template" placeholder="Use our template" v-model="select" :items="items"
          item-title="text" variant="outlined"></v-select>
      </v-col>
      <!-- <v-col md="2">
        <v-btn :disabled="spec.readOnly" class="tertiary depressed" variant="flat"
          @click="resetDialog = true">Reset</v-btn>
      </v-col> -->
    </v-row>
    <fields-richText ref="richText" :spec="richTextSpec" />
    <v-bottom-sheet v-model="resetDialog" inset>
      <v-list>
        <v-list-subheader>This will reset the text to the default template, are you
          sure?</v-list-subheader>
        <v-list-item color="primary" @click="reset()">Confirm</v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { vueApp } from "glib-web";
import FieldsRichText from "glib-web/components/fields/richText.vue";

const getState = (spec) => ({
  resetDialog: false,
  items: spec.items,
  template: spec.templateValue,
  select: null,
  richTextSpec: {
    view: 'fields-richText',
    name: spec.name,
    accept: "markdown",
    output: "markdown",
    value: spec.value,
    width: "matchParent",
    cacheKey: spec.cache_key,
    readOnly: spec.readOnly,
    onChange: spec.onChange
  }
});
export default {
  props: ["spec"],
  components: { "fields-richText": FieldsRichText },
  data: function () {
    return getState(this.spec);
  },
  methods: {
    reset() {
      this.renderRichText(this.select);
      this.resetDialog = false;
    },
  },
  watch: {
    select(val, oldVal) {
      if (!val) return;
      if (confirm("Attention: The data that has been filled in will be replaced with a template. Continue?")) {
        vueApp.richTextValues[this.spec.cacheKey] = null;
        this.richTextSpec = Object.assign({}, this.richTextSpec, { value: this.template[val] });
        console.log(this.template);
      }
    },
    spec: {
      handler(spec) {
        Object.assign(this, getState(spec));
      },
      immediate: true
    }
  },
};
</script>
