<template>
  <div>
    <Header :obj="spec.header" />
    <Hero :obj="spec.hero" />
    <Commitment :obj="spec.commitment" />
    <CurrentFeature :obj="spec.currentFeature" />
    <CurrentProject :obj="spec.currentProject" />
    <ContactUs :obj="spec.contactUs" />
    <Footer :obj="spec.footer" />
  </div>
</template>

<script>
import Hero from "./hero.vue";
import Commitment from "./commitment.vue";
import CurrentProject from "./currentProject.vue";
import ContactUs from "./contactUs.vue";
import Footer from "./footer.vue";
import Header from "./header.vue";
import CurrentFeature from "./currentFeature.vue";

export default {
  props: {
    spec: { type: Object, required: true },
  },
  components: {
    Header,
    Hero,
    Commitment,
    CurrentProject,
    ContactUs,
    Footer,
    CurrentFeature,
  },
};
</script>

<style lang="scss" scoped>
:deep(*) {
  font-family: "Montserrat", sans-serif !important;
}

:deep(h1) {
  font-size: 40px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.05em;
}

:deep(h2) {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 142%;

  letter-spacing: -0.05em;

  color: rgba(0, 0, 0, 0.6);
}

::deep(h3) {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 142%;

  text-align: center;
  letter-spacing: -0.05em;

  color: rgba(0, 0, 0, 0.6);
}

:deep(h4) {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;

  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.6);
}

:deep(p) {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  /* or 29px */
  color: rgba(0, 0, 0, 0.6);
}

:deep(.btn-primary) {
  background: #0A2A9E;
  border-radius: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 16px 32px;
}

:deep(.btn-secondary) {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  color: #0A2A9E;

  border: 1px solid #0A2A9E;
  border-radius: 60px;
  padding: 16px 32px;
}
</style>
