<template>
    <div class="flex w-full justify-center">
        <div class="lg:w-[1300px] w-full px-6 py-6">
            <a href="">
                <img :src="obj.logo" class="w-auto h-[32px]" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        obj: {
            type: Object
        }
    }
}
</script>