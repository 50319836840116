<template>
  <div
    class="flex justify-between items-center w-full lg:px-[80px] sm:px-8 lg:py-14 sm:py-8"
  >
    <!-- <h1 class="text-[#0A2A9E]">HotelZero</h1> -->
    <img :src="logo" alt="hotelzero" style="height: 75px" />
    <!-- <div class="inline-flex">
            <a class="mr-6 w-fit">
                <div class="rounded-[100px] px-6 py-3 text-[#0A2A9E] border-[#0A2A9E] border">
                    Login
                </div>
            </a>


            <a class="w-fit">
                <div class="rounded-[100px] px-6 py-3 text-[#0A2A9E] bg-[#EAF5FD]">
                    Sign Up
                </div>
            </a>
        </div> -->
  </div>
</template>

<script>
import logo from "../../../../../assets/images/logo_dark_hotelzero.svg";
export default {
  data() {
    return {
      logo: logo,
    };
  },
};
</script>