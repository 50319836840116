<template>
  <div :class="`py-[96px] ${styles.ppage} bg-[#F4F9FF] w-full align-center justify-center flex`">
    <div class="flex max-w-[1156px] w-full">
      <div class="lg:w-[448px] w-full lg:mr-6">
        <h1 class="mb-8">Driving purpose & sustainability through talent and technology</h1>
        <p class="lg:pb-[96px]">We help travel, tourism and experience-based businesses transition into a
          sustainable future. </p>
        <div class="my-14 w-full lg:hidden block">
          <img
            class="h-auto w-full"
            :src="obj.image"
          >
        </div>

        <a
          target="_blank"
          :href="obj.talk_to_us_url"
        >
          <button class="btn-primary">
            <span>Talk to us</span>
          </button>

        </a>
      </div>
      <div class="lg:w-[684px] sm:w-full hidden lg:block">
        <img
          class="h-auto w-full"
          :src="obj.image"
        >
      </div>
    </div>
  </div>
</template>
<script>
import styles from "./styles";
export default {
  props: {
    obj: {
      type: Object,
    },
  },
  data: () => ({
    styles: styles,
  }),
};
</script>