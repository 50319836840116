<template>
  <v-container class="term_wrap">
    <v-row>
      <v-col>
        <h1 class="blue_head text-center" style="margin-top: 24px;">{{ spec.heading }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="body_content" v-html="spec.introductions_title"></div>
          <div class="body_content" v-html="spec.introductions"></div>

          <div class="body_content" v-html="spec.users_and_customers_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.users_and_customers"></div>

          <div class="body_content" v-html="spec.eligibility_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.eligibility"></div>

          <div class="body_content" v-html="spec.account_registration_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.account_registration"></div>

          <div class="body_content" v-html="spec.license_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.license"></div>

          <div class="body_content" v-html="spec.proprietary_rights_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.proprietary_rights"></div>

          <div class="body_content" v-html="spec.user_content_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.user_content"></div>

          <div class="body_content" v-html="spec.warranties_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.warranties"></div>

          <div class="body_content" v-html="spec.indemnification_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.indemnification"></div>

          <div class="body_content" v-html="spec.third_party_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.third_party"></div>

          <div class="body_content" v-html="spec.general_terms_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.general_terms"></div>

          <div class="body_content" v-html="spec.question_title" style="margin-top: 80px;"></div>
          <div class="body_content" v-html="spec.question"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
export default {
  props: ["spec"]
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/_colors.scss";
</style>