<template>
  <div class="date-input-container" @click="triggerDatepicker">
    <v-icon icon="calendar_today" class="calendar-icon"></v-icon>
    <span class="date-text">{{ displayDate }}</span>
    <input :name="spec.name" type="date" class="date-input" ref="dateInput" v-model="dateValue" @change="updateDate" />
  </div>
</template>

<script>
export default {
  props: {
    spec: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateValue: this.extractDate(this.spec.value || null),
    };
  },
  computed: {
    displayDate() {
      if (!this.dateValue) {
        return "Add Due Date";
      }
      const date = new Date(this.dateValue);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
  },
  methods: {
    triggerDatepicker() {
      this.$refs.dateInput.showPicker();
    },
    updateDate(event) {
      if (event.target.value) {
        this.dateValue = event.target.value;
        this.$emit("update:value", this.dateValue);
      }

      if (this.spec.url) {
        const fileKey = this.spec.name
        GLib.action.execute({ action: 'http/patch', url: this.spec.url, formData: { [fileKey]: this.dateValue } }, {});
      }
    },
    extractDate(datetime) {
      if (!datetime) return null;
      if (typeof datetime !== "string") {
        console.warn("Expected a string, but got:", datetime);
        return null;
      }
      const [date] = datetime.split("T");
      return date;
    },
  },
  watch: {
    "spec.value": {
      immediate: true,
      handler(newValue) {
        this.dateValue = this.extractDate(newValue);
      },
    },
  },
};
</script>

<style scoped>
.date-input-container {
  display: flex;
  align-items: center;
  background-color: #f3f5f7;
  border-radius: 5px;
  padding: 8px 12px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.date-text {
  color: #555;
  font-size: 16px;
  white-space: nowrap;
}

.date-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.calendar-icon {
  font-size: 16px;
  color: #888;
  margin-right: 8px;
}
</style>