<template>
    <div class="flex flex-column justify-center align-center pt-[96px] pb-[56px] w-full border-y border-[#E6E6E6]">
        <div :class="`flex lg:flex-row flex-col-reverse lg:w-[1156px] w-full lg:justify-between ${styles.ppage}`">
            <div class="lg:w-[212px] w-full mb-lg-0 mb-14">
                <div class="flex flex-col flex-wrap items-center lg:items-start content-center lg:content-start">
                    <a href="">
                        <img class="w-[124px] h-auto mb-10" :src="obj.logo" />
                    </a>
                    <a target="_blank" :href="obj.linkedin_url">
                        <img class="w-[48px] h-auto" :src="obj.linkedin" />
                    </a>
                </div>
            </div>

            <div class="lg:w-[448px] w-full mb-lg-0 mb-14">
                <div class="font-semibold text-2xl mb-4">About Us</div>
                <p>Talent Basket is a sustainable tourism consultancy and software development company based in the USA
                    and Taiwan. Our specialty is the cross-sector of digital, sustainable, and experience economies.
                </p>
            </div>

            <div class="lg:w-[212px] w-full mb-lg-0 mb-14">
                <div class="font-semibold text-2xl mb-4">Contact Us</div>
                <p class="mb-4">201 Spear Street Suite 1190 San Francisco, CA 94105</p>
                <p>415.578.4608 info@talentbasket.com </p>
            </div>
        </div>
        <div class="pt-14 flex justify-center">
            <p>Copyright © 2023 Talent Basket, LLC</p>
        </div>
    </div>
</template>
<script>
import styles from './styles';

export default {
    data: () => ({ styles: styles }),
    props: {
        obj: {
            type: Object
        }
    }
}
</script>