<template>
  <v-card class="mx-auto" variant="outlined" style="border-radius: 20px; border-color: #E6E6E6;">
    <v-img class="white--text align-end" height="200" :cover="true" :src="spec.image_header_url">
    </v-img>


    <div class="px-10 pt-5 pb-10">
      <div class="flex w-full justify-end">
        <a v-if="spec.showEditLink" :href="spec.edit_url" class="primary--text" style="text-align: center">
          <v-icon color="#0A2A9E" size="24">edit</v-icon>
          <span class="ml-1">Edit</span>
        </a>
      </div>
      <div class="flex justify-start items-center">
        <div class="mr-4">
          <v-img width="109" :src="spec.logo_url" />
        </div>
        <div class="flex flex-column">
          <h3 class="mb-0">{{ spec.company.name }}</h3>
          <p v-if="spec.country_name" class="p2-light  mb-0"><v-icon color="#000">location_on</v-icon>
            <span class="ml-2">{{ spec.country_name }}</span>
          </p>
          <p v-if="spec.room_size" class="p2-light  mb-0">
            <span class="ml-2">{{ spec.room_size }}</span>
          </p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    spec: {
      type: Object,
      default: function () {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  border-radius: 20px
}

.share-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.company-profile-wrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  z-index: 2;
}

.company-detail {
  margin-top: auto;
  margin-left: 15px;
}

.highlight {
  width: 95%;
  margin-left: 0;
}

.company-logo {
  width: 100px;
}

.social-media {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  margin-left: 8px;
}

.button-wrapper {
  display: flex;
  gap: 8px;
}

.social-media .social-media-item {
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.social-media .social-media-item a {
  margin-left: 5px;
  text-decoration: none;
}

@media only screen and (min-width: 600px) {
  .company-profile-wrapper {
    position: absolute;
    top: 180px;
    flex-direction: row;
    margin-left: 30px;
    z-index: 2;
    align-items: center;
  }

  .image-header {
    // margin-bottom: 60px;
  }

  .social-media {
    flex-direction: row;
    margin-left: 30px;
    width: 60%;
    gap: 32px;
  }

  .social-media .social-media-item {
    display: flex;
    align-items: center;
  }

  .highlight {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 960px) {}
</style>
