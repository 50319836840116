<template>
  <div class="site_footer">
    <v-container>
      <div class="footer-top">
        <div class="footer-col footer-logo">
          <a v-if="spec.lightLogo" :href="spec.home_link" class="light-logo">
            <img :src="spec.lightLogo" :alt="spec.site_name" />
          </a>
        </div>
        <div class="footer-col">
          <ul class="footer-social-links">
            <li v-for="item in spec.social_links" :key="item.id">
              <a :href="item.link">
                <img :src="item.icon" :alt="item.title" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-middle">
        <div class="footer-col">
          <ul class="footer_contact">
            <li v-if="spec.contact_info.email">
              <a :href="'mailto:' + spec.contact_info.email">{{
                spec.contact_info.email
              }}</a>
            </li>

            <li v-if="spec.contact_info.us">
              US:
              <a :href="'tel:' + spec.contact_info.us">{{
                spec.contact_info.us
              }}</a>
            </li>
            <li v-if="spec.contact_info.taiwan">
              Taiwan:
              <a :href="'tel:' + spec.contact_info.taiwan">{{
                spec.contact_info.taiwan
              }}</a>
            </li>
          </ul>
        </div>
        <div class="footer-col footer-menu-col">
          <ul class="footer_menus">
            <li v-for="item in spec.menu" :key="item.id">
              <a v-if="item.menu" :href="item.link">{{ item.menu }}</a>
            </li>
          </ul>
        </div>
        <div v-if="spec.language" class="footer-col footer-register">
          <ul class="footer_menus">
            <li v-for="item in spec.language" :key="item.id">
              <a v-if="item.menu" :href="item.link">{{ item.menu }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <ul class="bottom-menu">
          <li v-for="item in spec.bottom_menu" :key="item.id">
            <a v-if="item.menu" :href="item.link">{{ item.menu }}.</a>
          </li>
        </ul>
        <div class="copyright">{{ spec.copyright }}</div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["spec"]
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/_colors.scss";
@import "../../../../../assets/stylesheets/_breakpoints.scss";

/*Footer*/
.site_footer {
  color: $white-color;
  background-color: $primary-color-dark;
  width: 100%;
  display: inline-block;
  padding: 50px 0 0;
}
.site_footer a {
  color: $white-color !important;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: 13px;
  font-weight: 500;
}
.site_footer a:hover,
.site_footer a:active,
.site_footer a:focus {
  color: $warning-color !important;
}
.footer_menus li {
  width: 50%;
  float: left;
  padding-right: 15px;
  margin: 5px 0;
}
.footer-col {
  display: inline-block;
  vertical-align: top;
  width: 24.5%;
}
.footer-col.footer-menu-col {
  width: 50%;
}
.footer-top {
  margin-bottom: 80px;
}
.footer-top .footer-col {
  vertical-align: middle;
}
.footer_contact {
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.site_footer .footer_contact a,
.site_footer .footer_contact li {
  text-transform: unset;
}
.footer-logo img {
  max-width: 127px;
}
.v-application .site_footer ul {
  padding-left: 0;
}
.footer_contact li {
  letter-spacing: 1.4px;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
.footer_contact li:first-child {
  margin-bottom: 80px;
}
.footer-register .yellow_head {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 1.4;
}
.v-application .footer-register .default_btn {
  margin-top: 30px;
  color: $warning-color !important;
  border-color: $warning-color;
  background-color: transparent;
  width: 100%;
  text-align: center;
}
.v-application .footer-register .default_btn:hover,
.v-application .footer-register .default_btn:active,
.v-application .footer-register .default_btn:focus {
  background-color: $warning-color;
  color: $white-color !important;
}
.footer-bottom {
  width: 100%;
  display: inline-block;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  padding: 35px 0 20px;
  margin: 35px 0 0;
}
.footer-bottom .copyright {
  width: 45%;
  float: left;
  padding-right: 25px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.82px;
}
.footer-bottom .bottom-menu {
  width: 55%;
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}
.footer-bottom .bottom-menu li {
  display: inline-block;
  margin-left: 30px;
}
.site_footer .footer-bottom .bottom-menu a {
  text-transform: unset;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.39px;
}
.bottom-menu li:first-child {
  margin-left: 0;
}
/*Footer*/

/*Where are*/
.where_are .blue_head {
  margin-bottom: 30px;
}
.where_are {
  padding-bottom: 80px;
}
/*Where are*/

@media (max-width: $breakpoint-large-desktop) {
  .footer-col {
    width: 24%;
  }
}
@media (max-width: $breakpoint-tablet) {
  .footer-col {
    width: 100%;
    text-align: center;
    margin: 0 0 20px;
  }
  .v-application .footer-register .default_btn {
    margin-top: 15px;
  }
  .footer_contact li:first-child,
  .footer_contact li {
    margin-bottom: 5px;
  }
  .footer-col.footer-register {
    margin-bottom: 10px;
  }
  .footer-col.footer-menu-col {
    width: 100%;
  }
  .footer_menus li {
    width: 100%;
    padding-right: 0;
  }
  .footer-bottom .copyright {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  .footer-bottom .bottom-menu {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .footer-logo {
    display: none;
  }
  .footer-top {
    margin-bottom: 10px;
  }
  .footer-register .yellow_head {
    font-size: 16px;
  }
  .v-application .footer-register .default_btn {
    width: 100%;
  }
  .site_footer {
    padding: 20px 0 0;
  }
  .footer-bottom {
    margin: 10px 0 0;
  }
}
</style>
