<template>
    <div :class="`w-full flex flex-column justify-center align-center ${styles.ppage} mb-[96px]`">
        <div class="relative lg:w-[1156px] w-full">
            <div class="w-full h-full bg-[#F4F9FF] border-0 rounded-[75px] py-14 px-4 relative z-30">
                <h1 class="mb-4 text-center">How can we help you?</h1>
                <p class="mb-6 text-center">Get in touch to create an impact-driven solutions</p>

                <div class="flex justify-center">
                    <a target="_blank" :href="obj.url">
                        <button class="btn-primary">Contact Us</button>
                    </a>
                </div>

            </div>
            <div class="glitter z-10"></div>
        </div>
    </div>
</template>

<style scoped>
.glitter {
    position: absolute;
    width: 178px;
    height: 178px;
    left: -32px;
    bottom: -32px;

    background: #FFF5DB;
    transform: rotate(90deg);
    border-radius: 0px 0px 100px 0px;
}
</style>

<script>
import styles from './styles';

export default {
    props: {
        obj: {
            type: Object
        }
    },
    data: () => ({ styles: styles })
}
</script>