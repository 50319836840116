<template>
  <div class="flex justify-center">
    <div class="flex items-center xs:flex-wrap sm:flex-nowrap">
      <template :key="'stepper-' + i" v-for="(step, i) in spec.steps">
          <div @[step.url&&`click`]="handleClick(step.url)"
            :class="`flex flex-col items-center ${step.url ? 'cursor-pointer' : ''}`">
            <div :class="`${bgClass(i, step)} ${bulletClass}`">
              {{ step.completed && spec.active_index != i + 1 ? '✓' : (i + 1) }}
            </div>
            <div
              :class="spec.active_index == i + 1 ? 'sm:block hidden text-center font-medium' : 'text-center sm:block hidden disabled'">
              {{
                step.detail }}</div>
          </div>

          <div v-if="i < spec.steps.length - 1" class="h-[1px] sm:w-[24px] opacity-75 bg-[#979797] mx-2"></div>
        </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    spec: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: function () {
    return {
      bulletClass: 'text-white text-xs pa-1 rounded-full w-[24px] h-[24px] flex justify-center items-center'
    };
  },
  methods: {
    handleClick(url) {
      GLib.action.execute({ action: 'windows/open-v1', url: url }, this);
    },
    bgClass(i, step) {
      let bgCls = '';

      if (this.spec.active_index == i + 1) {
        bgCls = 'bg-primary';
      } else if (step.completed) {
        bgCls = 'bg-[#01C851]';
      } else {
        bgCls = 'bg-[#A7ADB5]';
      }

      return bgCls;
    }
  }
};
</script>
<style lang="scss" scoped></style>