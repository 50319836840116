<template>
  <div class="pt-[128px] w-full justify-center align-center flex">
    <div class="flex flex-col lg:flex-row max-w-[1156px] justify-center align-center w-full">
      <div class="lg:w-[566px] w-full lg:mr-6">
        <img
          class="w-full h-auto object-none md:object-cover"
          :src="obj.image"
        />
      </div>
      <div :class="`lg:px-8 px-4 lg:max-w-[566px] md:w-full ${styles.ppage}}`">
        <h2 class="mb-4 lg:mb-14 md:mt-14 lg:mt-0 max-sm:mt-8">Our commitment</h2>
        <p>Sustainable transformation starts with talent. We design human capital-centric technology
          solutions
          to empower the sustainable development of global communities and creative experiences.</p>
      </div>

    </div>
  </div>
</template>

<script>
import styles from "./styles";
export default {
  props: {
    obj: {
      type: Object,
    },
  },
  data: () => ({
    styles: styles,
  }),
};
</script>