<template>
  <div :class="`w-full ${color} px-8 py-6 rounded-[16px] mb-10`">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <img :src="image" width="24" height="24" />
        <h4>{{ title }}</h4>
      </div>

      <v-icon v-if="edit" icon="edit" color="primary" @click="onClick()"></v-icon>
    </div>
    <p class="mt-6" v-if="subtitle">{{ subtitle }}</p>
  </div>
</template>

<script setup>
const { edit } = defineProps(['color', 'title', 'subtitle', 'image', 'edit']);

function onClick() {
  return GLib.action.execute(edit, this || {})
}
</script>