<template>
  <div>
    <label>
      <div :class="{ 'radio-wrapper': !spec.disabled, 'radio-wrapper-disabled': spec.disabled }">
        <input type="radio" v-model="fieldModel" :name="fieldName" :value="spec.radioValue"
          :checked="spec.value === spec.radioValue ? true : false" :disabled="spec.disabled">
        <img :src="spec.image" alt="option" />
        <p>{{ spec.label }}</p>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    spec: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    fieldName() {
      return this.spec.fieldName;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/stylesheets/_colors.scss";

.radio-wrapper {
  background-color: $white-color;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
  min-height: 200px;
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border: 2px solid $primary-color-dark;
  }

  /* Use :has() to change the border color when the radio is checked */
  &:has(input[type="radio"]:checked) {
    border: 2px solid $primary-color-dark;

    p {
      color: $primary-color-dark;
      font-weight: 700;
      line-height: 35px;
    }
  }

  p {
    margin-top: 8px;
    font-size: 22px;
    max-width: 160px;
    text-align: center;
    line-height: 35px;
  }
}

/* HIDE RADIO */
[type=radio] {
  position: absolute;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio]+img {
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.disable {
  width: 50px;
  height: 50px;
  opacity: 40%;
}

.radio-wrapper-disabled {
  background-color: $white-color;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
  min-height: 200px;
  opacity: 40%;

  p {
    margin-top: 8px;
    font-size: 22px;
    max-width: 160px;
    text-align: center;
    line-height: 35px;
  }

  img {
    width: 80px;
    height: 80px;
  }
}
</style>
